import React from 'react'
import MainHero from '../MainHero'
import heroimg from '../../assets/market-order-bg.png';
const Hero = () => {
  return (
    <div>
      <MainHero
      enheading="Set Your Market Order and Gain Control Over Your Trades"
      esheading="Establezca su orden de mercado y obtenga control sobre sus operaciones"
      endesc="Establish your trading strategy and manage your currency transactions with confidence."
      esdesc="Establezca su estrategia comercial y administre sus transacciones de divisas con confianza."
      enbtn="Set Order"
      esbtn="Establecer orden"
      img={heroimg}
      />
    </div>
  )
}

export default Hero
