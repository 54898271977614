import React from 'react';
import bg from '../../../../assets/hero-vid.webm';
import icon1 from '../../../../assets/money-management.webp';
import icon2 from '../../../../assets/Money.webp';
import icon3 from '../../../../assets/divisa.webp';
import icon4 from '../../../../assets/personas.webp';
import alianza from '../../../../assets/Alianza.webp';
import Calculator from '../../Calculator';
import { Link } from 'react-router-dom';

import mobimg from '../../../../assets/mobbg.webp'

const Hero = () => {
  const features = [
    {
      icon: icon1,
      title: "+1,200Millones",
      desc: "de dólares cambiados",
    },
    {
      icon: icon2,
      title: "Sin comisiones",
      desc: "por pagos locales e internacionales",
    },
    {
      icon: icon3,
      title: "Cuenta multidivisas",
      desc: "en EE.UU. totalmente gratis",
    },
    {
      icon: icon4,
      title: "+95mil",
      desc: "clientes registrados",
    },
  ];

  return (
    <div className="relative">
      {/* Background Video */}
      <video
        className="absolute md:block hidden top-0 left-0 w-full h-full object-cover z-0"
        src={bg}
        autoPlay
        loop
        muted
      ></video>

      <img src={mobimg} alt="" className="absolute md:hidden block top-0 left-0 w-full h-full object-cover z-0"/>
      
      {/* Content Wrapper */}
      <div className="max-w-[1300px] mx-auto flex flex-col lg:flex-row justify-between items-center py-8 lg:py-16 px-4 lg:px-10 z-10 relative">
        {/* Left Content Section */}
        <div className="text-center lg:text-left">
          <h1 className="text-4xl lg:text-5xl font-extrabold text-primary">
            El mejor tipo de cambio
          </h1>
          <p className="text-black/60 mt-4 mb-6">
            para que tus transacciones internacionales crezcan
          </p>

          {/* Features List */}
          <div>
            {features.map((f, index) => (
              <div key={index} className="flex gap-3 items-center my-4">
                <img className="w-[40px] h-[40px]" src={f.icon} alt="" />
                <div>
                  <h4 className="text-xl text-primary font-semibold">{f.title}</h4>
                  <p>{f.desc}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Link */}
          <Link
            className="bg-secondary rounded-lg py-2 px-6 hover:shadow-lg hover:shadow-black/50 duration-300 text-white"
            to={process.env.REACT_APP_SCHEDULE_DEMO}
          >
            Agendar cita
          </Link>
        </div>

        {/* Right Content Section */}
        <div className="mt-12 lg:mt-[23rem] text-center md:mr-16">
          <p className="text-primary font-semibold mb-4">Alianzas estratégicas :</p>
          <img className="w-[15rem] mx-auto lg:mx-0" src={alianza} alt="Alianza" />
        </div>
      

      {/* Calculator Component */}
      <div className="z-10">
        <Calculator />
      </div>
    </div></div>
  );
};

export default Hero;
