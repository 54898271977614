import React from 'react'
import bannerImg from '../../../../assets/Desk-Banner.webp'
import { Link } from 'react-router-dom'
const CalltoAction = () => {
    return (
        <div>
            <div style={{ backgroundImage: `url(${bannerImg})` }} className='h-[15rem] flex flex-col gap-3 justify-center items-center bg-cover'>
                <h1 className="md:text-4xl text-2xl font-semibold text-primary text-center">It’s easy, fast, secure and 100% digital</h1>
                <p className="text-primary font-semibold">Enjoy zero fees for SWIFT, Wire Transfers, and SPEI transactions.</p>
                <Link className='py-2 px-4 bg-secondary text-white rounded-lg hover:-translate-y-1 duration-200 shadow-lg hover:shadow-black/50' to={process.env.REACT_APP_SCHEDULE_DEMO}>Schedule Demo</Link>
            </div>
        </div>
    )
}

export default CalltoAction
