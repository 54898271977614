import testi1 from '../assets/J&RCapitalFundingLLC.svg'
import testi2 from '../assets/UltimumLogistics.svg';
import testi3 from '../assets/LitchfieldSpecialRisks,Inc.svg';
import testi4 from '../assets/FiscusCapitalFunding.svg';

const translations = {
    en:[
        {
            name: "J & R Capital Funding LLC",
            feedback: `"TKambio USA is the company to talk to for any type of currency exchange. EXTREMELY good rates and very professional. Make sure that if you will be doing business in other countries or simply need a change, you talk to TKambio USA".`,
            country:"Mexico",
            image: testi1,
          },
          {
            name: "Ultimum Logistics",
            feedback: `"Tkambio is like my in-house banker, it doesn't cost me, he gets a better exchange rate and helps me collect in the USA and pay for my international purchases, from Mexico."`,
            country:"Mexico",
            image: testi2,
          },
          {
            name: "Litchfield Special Risks, Inc.",
            feedback: `"¡Trying to manage payments and collections abroad is a nightmare! TKambio USA can help you expand to other countries by simplifying payment and currency management”.`,
            country:"USA",
            image: testi3,
          },
          {
            name: "Fiscus Capital Funding",
            feedback: `“TKambio has the best currency exchange and international payments platform. More than 34 different currencies are managed there. Contact TKambio USA for any of your foreign exchange or international payment needs.”.`,
            country:"USA",
            image: testi4,
          },
          {
            name: "J & R Capital Funding LLC",
            feedback: `"TKambio USA is the company to talk to for any type of currency exchange. EXTREMELY good rates and very professional. Make sure that if you will be doing business in other countries or simply need a change, you talk to TKambio USA".`,
            country:"Mexico",
            image: testi1,
          },
          {
            name: "Ultimum Logistics",
            feedback: `"Tkambio is like my in-house banker, it doesn't cost me, he gets a better exchange rate and helps me collect in the USA and pay for my international purchases, from Mexico."`,
            country:"Mexico",
            image: testi2,
          },
          {
            name: "Litchfield Special Risks, Inc.",
            feedback: `"¡Trying to manage payments and collections abroad is a nightmare! TKambio USA can help you expand to other countries by simplifying payment and currency management”.`,
            country:"USA",
            image: testi3,
          },
          {
            name: "Fiscus Capital Funding",
            feedback: `“TKambio has the best currency exchange and international payments platform. More than 34 different currencies are managed there. Contact TKambio USA for any of your foreign exchange or international payment needs.”.`,
            country:"USA",
            image: testi4,
          },
    ],
    es:[
        {
            name: "J & R Capital Funding LLC",
            feedback: `"TKambio USA es la empresa indicada para cualquier tipo de cambio de divisas. Tarifas EXTREMADAMENTE buenas y muy profesionales. Asegúrate de hablar con TKambio USA si harás negocios en otros países o simplemente necesitas un cambio".`,
            country: "Mexico",
            image: testi1,
        },
        {
            name: "Ultimum Logistics",
            feedback: `"Tkambio es como mi banquero interno, no me cuesta, obtengo un mejor tipo de cambio y me ayuda a cobrar en EE.UU. y pagar mis compras internacionales desde México."`,
            country: "Mexico",
            image: testi2,
        },
        {
            name: "Litchfield Special Risks, Inc.",
            feedback: `"¡Intentar gestionar pagos y cobros en el extranjero es una pesadilla! TKambio USA puede ayudarte a expandirte a otros países simplificando la gestión de pagos y divisas".`,
            country: "USA",
            image: testi3,
        },
        {
            name: "Fiscus Capital Funding",
            feedback: `"TKambio tiene la mejor plataforma de intercambio de divisas y pagos internacionales. Allí se manejan más de 34 monedas diferentes. Contacta a TKambio USA para cualquier necesidad de cambio de divisas o pagos internacionales."`,
            country: "USA",
            image: testi4,
        },
        {
            name: "J & R Capital Funding LLC",
            feedback: `"TKambio USA es la empresa indicada para cualquier tipo de cambio de divisas. Tarifas EXTREMADAMENTE buenas y muy profesionales. Asegúrate de hablar con TKambio USA si harás negocios en otros países o simplemente necesitas un cambio".`,
            country: "Mexico",
            image: testi1,
        },
        {
            name: "Ultimum Logistics",
            feedback: `"Tkambio es como mi banquero interno, no me cuesta, obtengo un mejor tipo de cambio y me ayuda a cobrar en EE.UU. y pagar mis compras internacionales desde México."`,
            country: "Mexico",
            image: testi2,
        },
        {
            name: "Litchfield Special Risks, Inc.",
            feedback: `"¡Intentar gestionar pagos y cobros en el extranjero es una pesadilla! TKambio USA puede ayudarte a expandirte a otros países simplificando la gestión de pagos y divisas".`,
            country: "USA",
            image: testi3,
        },
        {
            name: "Fiscus Capital Funding",
            feedback: `"TKambio tiene la mejor plataforma de intercambio de divisas y pagos internacionales. Allí se manejan más de 34 monedas diferentes. Contacta a TKambio USA para cualquier necesidad de cambio de divisas o pagos internacionales."`,
            country: "USA",
            image: testi4,
        },
        
    ]
}

export default translations