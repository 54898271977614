import React, { useContext } from "react";
import english from "../../assets/english.png";
import spanish from "../../assets/es.png";
import calculator from "../../assets/calculator.PNG";
import trans from "../../assets/global-transfer.PNG";
import monex from "../../assets/monex.png";
import fast from '../../assets/fast-transfer.png';
import dedicate from '../../assets/dedicate-manager.png';
import support from '../../assets/24-hour-support.png';
import { LanguageContext } from "../../context/LanguageContext";

const Simplify = () => {
  const { language } = useContext(LanguageContext);

  // Translations for English and Spanish
  const translations = {
    en: {
      title: "Simplify Your Business Transactions Worldwide",
      description:
        "Open your account in minutes to manage multiple currencies with secure banking and dedicated support.",
      holdCurrenciesTitle: "Hold Multiple Currencies",
      holdCurrenciesDescription:
        "Easily manage all your currency needs within a single account. This feature allows you to streamline transactions, reduce the complexity of maintaining multiple accounts, and gain greater control over your finances.",
      currencyCalculatorTitle: "Tkambio Currency Calculator",
      currencyCalculatorDescription:
        "Easily convert currencies and access real-time exchange rates to make informed financial decisions and optimize your transactions.",
      globalTransfersTitle: "Global Transfers",
      globalTransfersDescription:
        "Send and receive payments worldwide, simplifying cross-border transactions.",
      complianceTitle: "Compliance & Security",
      complianceDescription:
        "Rest easy with accounts that meet financial regulations, backed by Monex's robust infrastructure, providing secure solutions for your global operations.",
      fastReliable: "Fast, Reliable Transfers",
      fastReliableDesc:
        "Get quick domestic and international transfers to keep your business moving.",
      dedicateManage:"Dedicated Account Manager",
      dedicateDesc:"Get personalized help from an account manager who understands your business",
      hourSupport:"24/7 Support",
      hourSupportDesc:"Count on expert assistance anytime to ensure smooth, secure global transactions."
    },
    es: {
      title: "Simplifica las Transacciones de tu Negocio en Todo el Mundo",
      description:
        "Abre tu cuenta en minutos para gestionar múltiples monedas con banca segura y soporte dedicado.",
      holdCurrenciesTitle: "Mantén Múltiples Monedas",
      holdCurrenciesDescription:
        "Gestiona fácilmente todas tus necesidades de moneda en una sola cuenta. Esta función te permite agilizar las transacciones, reducir la complejidad de mantener múltiples cuentas y tener un mayor control sobre tus finanzas.",
      currencyCalculatorTitle: "Calculadora de Moneda Tkambio",
      currencyCalculatorDescription:
        "Convierte monedas fácilmente y accede a tasas de cambio en tiempo real para tomar decisiones financieras informadas y optimizar tus transacciones.",
      globalTransfersTitle: "Transferencias Globales",
      globalTransfersDescription:
        "Envía y recibe pagos en todo el mundo, simplificando las transacciones internacionales.",
      complianceTitle: "Cumplimiento y Seguridad",
      complianceDescription:
        "Descansa tranquilo con cuentas que cumplen con las regulaciones financieras, respaldadas por la sólida infraestructura de Monex, que brinda soluciones seguras para tus operaciones globales.",
      fastReliable: "Transferencias Rápidas y Confiables",
      fastReliableDesc:
        "Obtén transferencias rápidas nacionales e internacionales para mantener tu negocio en movimiento.",
        dedicateManage:"Administrador de cuentas dedicado",
        dedicateDesc:"Obtenga ayuda personalizada de un administrador de cuentas que entiende su negocio",
        hourSupport:"24/7 apoyo",
        hourSupportDesc:"Cuente con asistencia experta en cualquier momento para garantizar transacciones globales seguras y sin problemas."
    },
  };


  const t = translations[language]; // Shortcut for translations


  const banner = [
    { img: fast, title: t.fastReliable, desc: t.fastReliableDesc },
    {img:dedicate,title:t.dedicateManage,desc:t.dedicateDesc},
    {img:support,title:t.hourSupport,desc:t.hourSupportDesc},
  ]


  return (
    <>
      <div className="max-w-[1200px] mx-auto mt-28 mb-6 px-4 ">
        <h1 className="text-center text-primary font-semibold text-4xl md:text-5xl leading-[1.2]">
          {t.title}
        </h1>
        <p className="text-textcolor/60 text-center text-lg md:text-2xl my-4">
          {t.description}
        </p>
        {/* Currency Management Section */}
        <div className="bg-[#FDFFEC] flex flex-col md:flex-row items-center md:justify-between p-6 md:p-16 rounded-xl gap-6">
          <div className="md:w-[60%] text-center md:text-left">
            <h1 className="text-2xl md:text-3xl font-semibold text-dark">
              {t.holdCurrenciesTitle}
            </h1>
            <p className="text-textcolor/60 mt-4 md:mt-6 text-base md:text-xl font-medium">
              {t.holdCurrenciesDescription}
            </p>
          </div>
          <div className="md:w-[25%] w-full flex flex-col gap-4">
            <div className="flex justify-between bg-white p-4 rounded-xl items-center">
              <img src={english} alt="USD" className="w-12" />
              <div className="flex-1 ml-4">
                <p className="text-textcolor/60 font-semibold text-lg">USD</p>
                <p className="mt-2 text-2xl font-medium">$2,345.82</p>
              </div>
            </div>
            <div className="flex justify-between bg-white p-4 rounded-xl items-center">
              <img src={spanish} alt="MXN" className="w-12" />
              <div className="flex-1 ml-4">
                <p className="text-textcolor/60 font-semibold text-lg">MXN</p>
                <p className="mt-2 text-2xl font-medium">$2,345.82</p>
              </div>
            </div>
          </div>
        </div>
        {/* Cards Section */}
        <div className="flex flex-col md:flex-row gap-6 my-6">
          {/* Currency Calculator */}
          <div className="bg-[#EAFFF1]  md:w-[60%] px-6 md:px-12 pt-16 rounded-xl">
            <h1 className="text-2xl md:text-4xl font-semibold text-dark">
              {t.currencyCalculatorTitle}
            </h1>
            <p className="text-textcolor/60 my-4 text-base md:text-xl font-medium">
              {t.currencyCalculatorDescription}
            </p>
            <img src={calculator} alt="Currency Calculator" className="rounded-3xl w-full" />
          </div>
          {/* Global Transfers */}
          <div className="bg-[#FFF6EF] flex flex-col justify-end gap-[6rem]  md:w-[40%] pl-6 md:pl-12 pt-16 rounded-xl">
            <div>
            <h1 className="text-2xl md:text-4xl font-semibold text-dark">
              {t.globalTransfersTitle}
            </h1>
            <p className="text-textcolor/60 my-4 text-base md:text-xl font-medium">
              {t.globalTransfersDescription}
            </p>
            </div>
            <img src={trans} alt="Global Transfers" className="rounded-3xl w-[100%]" />
          </div>
        </div>
        {/* Compliance & Security */}
        <div className="bg-[#ECFCFF] px-6 md:px-9 py-6 rounded-xl flex md:flex-row flex-col items-center gap-6">
          <div className="md:w-1/2">
            <h1 className="text-3xl md:text-5xl font-semibold">{t.complianceTitle}</h1>
            <p className="text-textcolor/60 font-medium text-lg md:text-xl mt-6">
              {t.complianceDescription}
            </p>
          </div>
          <img src={monex} alt="Compliance" className="md:w-1/2 w-full" />
        </div>
      </div>
      {/* Banner Section */}
      <div className="bg-secondary py-12 px-4">
        <div className="max-w-[1200px] mx-auto flex md:flex-row flex-col gap-6 justify-between text-white">
          {banner.map((item, idx) => (
            <div key={idx} className="flex-1">
              <img src={item.img} alt={item.title} className="w-16 mb-4" />
              <h4 className="text-xl md:text-2xl font-semibold">{item.title}</h4>
              <p className="text-white/70">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Simplify;
