import usd from "../../assets/usd copy.png";
import euro from "../../assets/euro.png";
import gbp from "../../assets/gbp copy.png";
import mxn from "../../assets/mxn copy.png";
import aed from "../../assets/aed.png";
import aud from "../../assets/aud copy.png";
import bgn from "../../assets/bgn.png";
import bhd from "../../assets/bhd copy.png";

const CurrencyProfile = () => {



  const currency = [
    {
      name: "USD",
      curr: "U.S. dollar",
      icon: usd,
    },
    {
      name: "EUR",
      curr: "Euro",
      icon: euro,
    },
    {
      name: "GBP",
      curr: "British pound",
      icon: gbp,
    },
    {
      name: "MXN",
      curr: "Mexican peso",
      icon: mxn,
    },
    {
      name: "AED",
      curr: "UAE Dirham",
      icon: aed,
    },
    {
      name: "AUD",
      curr: "Australian dollar",
      icon: aud,
    },
    {
      name: "BGN",
      curr: "Bulgarian Lev",
      icon: bgn,
    },
    {
      name: "BHD",
      curr: "Bareini Dinar",
      icon: bhd,
    },
  ];

  return (
    <div className="max-w-[1200px] mx-auto my-36">
      <h1 className="text-5xl text-primary font-semibold text-center">
        Currency Profiles
      </h1>
      <p className="text-xl text-textcolor/60 font-semibold text-center">
        Manage and track multiple currencies with real-time updates and seamless
        conversions for smooth global transactions.
      </p>
      <div className="mt-9 grid md:grid-cols-4 grid-cols-2 mx-4 gap-16">
        {currency.map((item, index) => {
          return (
            <div className="flex gap-2 items-center">
              <img src={item.icon} alt="" />
              <div>
                <h4 className="text-2xl font-semibold text-textcolor/80">{item.name}</h4>
                <p className="text-textcolor/60 font-semibold">{item.curr}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CurrencyProfile;
