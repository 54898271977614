import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";

import usd from "../../assets/usd copy.png";
import euro from "../../assets/euro.png";
import gbp from "../../assets/gbp copy.png";
import mxn from "../../assets/mxn copy.png";
import aed from "../../assets/aed.png";
import aud from "../../assets/aud copy.png";
import bgn from "../../assets/bgn.png";
import bhd from "../../assets/bhd copy.png";
const Slider = () => {
  const images = [
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
    usd,
    euro,
    gbp,
    mxn,
    aed,
    aud,
    bgn,
    bhd,
  ];
  return (
    <div className="my-28">
      <h3 className="text-3xl font-semibold text-center text-primary mb-9">
        Tkambio has Broad Access to Global Markets and Currencies
      </h3>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={10}
        breakpoints={{
          320: {
            slidesPerView: 5, // Show 1 card on mobile
          },
          640: {
            slidesPerView: 10, // Show 1.5 cards on small screens
          },
          768: {
            slidesPerView: 12, // Show 2 cards on tablets
          },
          1024: {
            slidesPerView: 15, // Show 3.5 cards on desktop
          },
        }}
        centeredSlides={true}
        autoplay={{
          delay: 0, // Time in ms between slides
          disableOnInteraction: false, // Keeps autoplay running after user interaction
        }}
        speed={1500}
        loop={true} // Enables infinite loop
      >
        {images.map((i) => {
          return (
            <SwiperSlide>
              <img className="md:w-[6rem]" src={i} alt="" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Slider;
