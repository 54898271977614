const translations = {
    en: {
      nosotros: "About Us",
      solucionesDigitales: "Solutions",
      blog: "Blog",
      testimonios: "Testimonials",
      preguntasFrecuentes: "FAQ",
      dropdownItems: [
        { name: "Currency Exchange", desc: "Get the best exchange rate and optimize your profitability",link:'/currency-exchange' },
        { name: "International Payments", desc: "No commission charges or maintenance fees",link:'/international-payments' },
        { name: "Market Orders", desc: "Set your exchange rate and control your operations",link:'/market-orders' },
        { name: "Forwards", desc: "Forget about exchange rate volatility",link:'/forward-contract' },
        { name: "TKambio Credit", desc: "Quick and easy financing without credit risk",link:'/tkambio-credit' },
      ],
      iniciarSesion: "Log In",
      registrarse: "Register",
      buttonText: "Spanish",
    },
    es: {
      nosotros: "Nosotros",
      solucionesDigitales: "Soluciones",
      blog: "Blog",
      testimonios: "Testimonios",
      preguntasFrecuentes: "FAQ",
      dropdownItems: [
        { name: "Cambio de Divisas", desc: "Obtén el mejor tipo de cambio y optimiza tu rentabilidad", link:"/currency-exchange" },
        { name: "Pagos Internacionales", desc: "Sin cobros por comisiones ni mantenimiento" , link:"/international-payments"},
        { name: "Market Orders", desc: "Define tu tipo de cambio y controla tus operaciones", link:"/market-orders" },
        { name: "Forwards", desc: "Olvídate de la volatilidad del tipo de cambio", link:"/forward-contract" },
        { name: "TKambio Credit", desc: "Financiamiento rápido y sencillo sin asumir riesgos crediticios", link:"/tkambio-credit" },
      ],
      iniciarSesion: "Iniciar sesión",
      registrarse: "Registrarse",
      buttonText: "English",
    },
  };

 export default translations;