import React from 'react'
import vid from '../../../../assets/Hoding-Dashboard.mp4'
import { Link } from 'react-router-dom'

const Dashboard = () => {
  return (
    <div className='max-w-[1300px] mx-auto my-28'>
      <h1 className="md:text-4xl text-3xl text-center text-primary font-semibold">Your company will conquer the world <br />
      with your currencies in one place</h1>
      <div className="flex md:flex-row flex-col gap-9 items-center my-8">
      <video className='w-[1050px]' src={vid} autoPlay loop></video>
      <div className='flex flex-col'>
        <div className='bg-[#EBEEF6] p-3 text-center my-4 rounded-lg'>No foreign accounts</div>
        <div className='bg-[#EBEEF6] p-3 text-center my-4 rounded-lg'>No account maintenance free</div>
        <div className='bg-[#EBEEF6] p-3 text-center my-4 rounded-lg'>No minimum balance required</div>
        <div className='bg-[#EBEEF6] p-3 text-center my-4 rounded-lg'>Get your FREE multi-currency account</div>
        <Link className='bg-secondary block text-center rounded-lg py-3 md:px-0 px-8 text-white font-medium md:mt-9' target='_blank' to={process.env.REACT_APP_REGISTER}>Waiting List</Link>
      </div>
      </div>
    </div>
  )
}

export default Dashboard
