import React from 'react'
import logo from '../assets/logo-2.png'
import { FaFacebookSquare, FaInstagramSquare, FaYoutube, FaLinkedin } from "react-icons/fa";
import { Link } from 'react-router-dom';
import en from '../assets/USD.png'
import es from '../assets/MXN.png'

const Footer = () => {

    const services = [
        {
            name:"Currency Exchange",
            link:"",
        },
        {
            name:"Global Payment",
            link:"",
        },
        {
            name:"X Account Multi-Currency",
            link:"",
        },
        {
            name:"Market Orders",
            link:"",
        },
        {
            name:"Forwards",
            link:"",
        },
        {
            name:"TKambio Credit",
            link:"",
        },
    ]

    const contacts = [
        {
            name:"EMAIL: support@tkambio.us",
            link:"mailto:support@tkambio.us",
        },
        {
            name:"WHATSAPP: +52 656 410 6480",
            link:"https://wa.me/+526564106480",
        },
        {
            name:"USA: +1 (915) 955-2646",
            link:"tel:+19159552446",
        },
        {
            name:"Mexico: +52 656 410 6480",
            link:"tel:+526564106480"
        }
    ]
    return (
    <>
        <div className='bg-white border-t border-primary py-16 px-6'>
            <div className="max-w-[1300px] mx-auto grid md:grid-cols-5 grid-cols-2 gap-8">
                {/* LOGO & SOCIAL SECTION */}
                <div className='md:col-span-1 col-span-2 md:block flex flex-col items-center'>
                    <img className='w-[150px]' src={logo} alt="" />
                    <div className='flex gap-3 my-6'>
                        <Link 
                        target='_blank' 
                        to={process.env.REACT_APP_FACEBOOK} 
                        className='bg-blue-500 text-xl shadow-lg shadow-black/30 p-2 text-white rounded-xl hover:-translate-y-1 duration-300'>
                            <FaFacebookSquare />
                        </Link>
                        <Link 
                        target='_blank' 
                        to={process.env.REACT_APP_INSTAGRAM}
                        className='bg-pink-500 text-xl shadow-lg shadow-black/30 p-2 text-white rounded-xl hover:-translate-y-1 duration-300'>
                            <FaInstagramSquare  />
                        </Link>
                        <Link 
                        target='_blank' 
                        to={process.env.REACT_APP_YOUTUBE} 
                        className='bg-red-500 text-xl shadow-lg shadow-black/30 p-2 text-white rounded-xl hover:-translate-y-1 duration-300'>
                            <FaYoutube />
                        </Link>
                        <Link 
                        target='_blank' 
                        to={process.env.REACT_APP_LINKEDIN} 
                        className='bg-sky-600 text-xl shadow-lg shadow-black/30 p-2 text-white rounded-xl hover:-translate-y-1 duration-300'>
                            <FaLinkedin />
                        </Link>
                    </div>
                </div>
                {/* SERVICES SECTION */}
                <div>
                    <h4 className="text-primary font-semibold text-xl mb-3 uppercase">Services</h4>
                    <ul className='flex flex-col gap-2 text-primary font-medium'>
                        {services.map((i,index)=>{
                            return(
                                <li key={index}><Link className='text-sm hover:underline' to={i.link}>{i.name}</Link></li>
                            )
                        })}
                    </ul>
                </div>
                {/* HEADQUARTERS SECTIONS */}
                <div>
                <h4 className="text-primary font-semibold text-xl mb-3 uppercase">Headquarters</h4>
                <ul className='flex flex-col gap-2 text-sm text-primary font-medium'><li>USA</li><li>Mexico</li></ul>
                </div>
                {/* CONTACT US */}
                <div>
                    <h4 className="text-primary font-semibold text-xl mb-3 uppercase">CONTACT US</h4>
                        <ul className='flex flex-col gap-2 text-sm text-primary font-medium'>
                            {contacts.map((i,index)=>{
                                return(
                                    <li key={index} className='hover:underline'><Link to={i.link}>{i.name}</Link></li>
                                )
                            })}
                        </ul>
                </div>
                {/* OFFICE HOURS & LANGUAGE */}
                <div>
                <h4 className="text-primary font-semibold text-xl mb-3 uppercase">OFFICE HOURS</h4>
                <ul className='flex flex-col gap-2 text-sm text-primary font-medium'>
                    <li>Monday - Friday</li>
                    <li>9:00 a.m - 6:00 p.m UTC</li>
                </ul>
                <h4 className="text-primary font-semibold text-xl mb-3 uppercase mt-6">Languages</h4>
                <div className="flex gap-4 flex-col">
                    <div className="flex items-center gap-2">
                    <img className='rounded-full w-[23px] h-[23px]' src={en} alt="" />
                    <p className='text-sm text-primary font-medium'>ENGLISH</p>
                    </div>
                    <div className="flex items-center gap-2">
                    <img className='rounded-full w-[20px] h-[20px]' src={es} alt="" />
                    <p className='text-sm text-primary font-medium'>SPANISH</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className='text-center bg-primary text-white py-2'>Copyright 2024 | Tkambio | All Rights Reserved | Developed by <Link to='https://www.linkedin.com/in/muhammad-abu-bakar-982007338/' target='_blank' className='underline'>Muhammad Abu Bakar</Link></div>
        </>
    )
}

export default Footer
