import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import { LanguageProvider } from './context/LanguageContext';
import Footer from './components/Footer';
import Chatbot from './components/Chatbot'
import ForwardContract from './pages/ForwardContract';
import MarketOrder from './pages/MarketOrder';
import ForeignExchange from './pages/ForeignExchange';
import MultiCurrency from './pages/MultiCurrency';
import TkambioCredit from './pages/TkambioCredit';
import ScrollToTop from './components/ScrollToTop';
import FAQs from './components/Home/FAQs';

function App() {
  return (
    <>
    <BrowserRouter>
    <LanguageProvider>
    <Header/>
    <ScrollToTop/>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/forward-contract' element={<ForwardContract/>} />
        <Route path='/market-orders' element={<MarketOrder/>} />
        <Route path='/currency-exchange' element={<ForeignExchange/>} />
        <Route path='/international-payments' element={<MultiCurrency/>} />
        <Route path='/tkambio-credit' element={<TkambioCredit/>} />

      </Routes>
      <Chatbot />
      <FAQs/>
    <Footer/>
    </LanguageProvider>
    </BrowserRouter>
    </>
  );
}




export default App;
