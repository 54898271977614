import React, { useContext } from "react";
import Card from "../Card";
import { LanguageContext } from "../../context/LanguageContext";

import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";


const CardsSection = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      card1heading: "Seize the Moment with Instant Orders",
      card1desc:
        "In trading, timing is everything. With Tkambio’s instant order execution, your trades are processed immediately at live market prices, ensuring you never miss a chance to act fast and capture market opportunities. Stay ahead with every trade, right when it matters most.",
      card1btn: "Execute Order",

      card2heading: "Simplified Trading for Easy Execution",
      card2desc:
        "Trade with confidence using Tkambio’s intuitive interface. It’s designed for simplicity, allowing you to place orders quickly and efficiently without the hassle of complicated steps. Perfect for businesses that prioritize speed and ease.",
      card2btn: "Try it Out",

      card3heading: "Stay Informed with Real-Time Pricing",
      card3desc:
        "Get the advantage of live market prices for every order. With Tkambio, you have up-to-the-minute data at your fingertips, allowing you to make informed decisions in the moment and act with confidence.",
      card3btn: "Get Started",

    },
    es: {
      card1heading: "Aprovecha el momento con pedidos instantáneos",
      card1desc:
        "En el trading, el tiempo lo es todo. Con la ejecución instantánea de órdenes de Tkambio, sus operaciones se procesan inmediatamente a precios de mercado en vivo, lo que garantiza que nunca pierda la oportunidad de actuar rápido y capturar oportunidades de mercado. Manténgase a la vanguardia en cada operación, justo cuando más importa.",
      card1btn: "Ejecutar orden",

      card2heading: "Negociación simplificada para una fácil ejecución",
      card2desc:
        "Opere con confianza utilizando la interfaz intuitiva de Tkambio. Está diseñada para ser simple, lo que le permite realizar pedidos de manera rápida y eficiente sin la molestia de pasos complicados. Perfecto para empresas que priorizan la velocidad y la facilidad",
      card2btn: "Pruébalo",

      card3heading: "Manténgase informado con los precios en tiempo real",
      card3desc:
        "Obtenga la ventaja de los precios de mercado en vivo para cada pedido. Con Tkambio, tiene datos actualizados al minuto a su alcance, lo que le permite tomar decisiones informadas en el momento y actuar con confianza.",
      card3btn: "Comenzar",

    },
  };

  const t = translations[language];
  return (
    <div className="bg-[#F2F4FF66] py-4">
      <Card
        title={t.card1heading}
        p={t.card1desc}
        btn={t.card1btn}
        img={card1}
      />


      <Card
        title={t.card3heading}
        p={t.card3desc}
        btn={t.card3btn}
        img={card2}
        styles={"order-first"}
      />
      <Card
        title={t.card3heading}
        p={t.card3desc}
        btn={t.card3btn}
        img={card3}
      />

      
    </div>
  );
};

export default CardsSection;
