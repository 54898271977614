import React from 'react'
import img from '../../../../assets/meettkambio.png'
import logo from '../../../../assets/logo-2.png'
import calendario from '../../../../assets/calendario.png'
import dinero from '../../../../assets/transferencia-de-dinero.png'
import volando from '../../../../assets/dinero-volando.png'

import { Link } from 'react-router-dom'

const d = [
    {
        icon:calendario,
        title:"+6 años",
        desc:"en el mercado latinoamericano"
    },
    {
        icon:dinero,
        title:"+1,200M",
        desc:"dólares cambiados"
    },
    {
        icon:volando,
        title:"+6 años",
        desc:"transacciones exitosas"
    },
]

const MeetTkambio = () => {
  return (
    <>
    <div className='bg-[#EBEEF6] py-8'>
      <div className="max-w-[1300px] mx-auto">
        <div className="md:flex md:px-0 px-6 justify-between items-center">
            <div>
                <div className="flex gap-4">
                    <h4 className="text-primary font-bold text-3xl">Conoce</h4>
                    <img className='w-[9rem]' src={logo} alt="" />
                </div>
                <p className='text-black/60 md:w-[30rem] my-4'>Somos una plataforma aliada para empresas que realizan transacciones con divisas internacionales. Con nosotros operas de forma rápida, segura, digital y sin pagar comisión.</p>
                <Link className='bg-secondary text-white rounded-lg px-4 py-1'>Agendar cita</Link>
            </div>
            <div className='md:block hidden'><img className='md:w-[600px]' src={img} alt="" /></div>
        </div>
      </div>
    </div>

    <div className="max-w-5xl my-16 mx-auto ">
        <div className="grid md:grid-cols-3 grid-cols-1 gap-3">
            {d.map((i,index)=>{
                return(
                    <div key={index} className='flex flex-col items-center text-center'>
                        <img className='w-[6rem]' src={i.icon} alt="" />
                        <h4 className="text-4xl text-primary font-bold">{i.title}</h4>
                        <p className='text-black/60'>{i.desc}</p>
                    </div>
                )
            })}
        </div>
    </div>

    </>
  )
}

export default MeetTkambio
