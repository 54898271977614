import React, { useEffect, useState } from 'react';
import mxn from '../../assets/MXN.png';
import cad from '../../assets/CAD.png';
import chf from '../../assets/CHF.png';
import aud from '../../assets/AUD.png';
import eur from '../../assets/EUR.png';
import gbp from '../../assets/GBP.png';
import jpy from '../../assets/JPY.png';
import usd from '../../assets/USD.png';
import { Link } from 'react-router-dom';

const currencyImages = {
  USD: usd,
  MXN: mxn,
  CAD: cad,
  CHF: chf,
  AUD: aud,
  EUR: eur,
  GBP: gbp,
  JPY: jpy,
};

const CalculatorEn = () => {
  const [fromCurrency, setFromCurrency] = useState('USD');
  const [toCurrency, setToCurrency] = useState('MXN');
  const [exchangeRate, setExchangeRate] = useState(null);
  const [amount, setAmount] = useState(1000);  // Initial amount as number
  const [convertedAmount, setConvertedAmount] = useState(0);

  const fetchExchangeRate = async (from, to) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}?pair=${from}${to}=X`);
      const data = await response.json();
      if (data && data.regularMarketPrice) {
        setExchangeRate(data.regularMarketPrice);
      }
    } catch (error) {
      console.error('Error fetching exchange rate:', error);
    }
  };

  useEffect(() => {
    fetchExchangeRate(fromCurrency, toCurrency); // Initial fetch

    // Set interval to update exchange rate every 10 minutes (600000ms)
    const intervalId = setInterval(() => {
      fetchExchangeRate(fromCurrency, toCurrency);
    }, 600000); // 600000 ms = 10 minutes

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [fromCurrency, toCurrency]);

  useEffect(() => {
    if (exchangeRate) {
      setConvertedAmount((amount * exchangeRate).toFixed(2));
    }
  }, [amount, exchangeRate]);

  const handleAmountChange = (e) => {
    // Remove commas for calculation
    const rawValue = e.target.value.replace(/,/g, '');  // Remove commas before setting the value
    setAmount(parseFloat(rawValue) || 0);  // Convert to number or 0 if invalid input

    // Format the value with commas for display
    const formattedValue = new Intl.NumberFormat().format(rawValue);  // Format the number with commas
    e.target.value = formattedValue;  // Set the input value to formatted one
  };

  const handleFromCurrencyChange = (e) => {
    setFromCurrency(e.target.value);
  };

  const handleToCurrencyChange = (e) => {
    setToCurrency(e.target.value);
  };

  // Function to format numbers with commas
  const formatWithCommas = (value) => {
    return new Intl.NumberFormat().format(value);
  };

  return (
    <div className="shadow-lg  rounded-xl flex justify-center items-center md:mt-0 mt-9 w-[400px]">
      <div className="bg-white rounded-lg p-5">
        <h1 className="text-3xl text-primary font-semibold">GoodBye Commisions</h1>
        <div className="flex items-center justify-between my-2 text-xl text-primary font-medium">
          <div className="bg-indigo-100 border-2 border-indigo-300 rounded-xl p-2">
            SEND: <select className='bg-indigo-100 outline-none' value={fromCurrency} onChange={handleFromCurrencyChange}>
              {Object.keys(currencyImages).map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
          <div className="bg-indigo-100 border-2 border-indigo-300 rounded-xl p-2">
            RECIEVE: <select className='bg-indigo-100 outline-none' value={toCurrency} onChange={handleToCurrencyChange}>
              {Object.keys(currencyImages).map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* YOU SEND */}
        <p className="text-primary font-semibold">You Send</p>
        <div className="shadow bg-white p-4 rounded-lg text-xl mt-2 flex justify-between w-full">
          <div>
            <input
              type="text"
              className="w-full outline-none"
              value={formatWithCommas(amount)} // Format the amount with commas for display
              onChange={handleAmountChange}
            />
          </div>
          <div className="flex items-center">
            <img className="w-[20px] h-[20px]" src={currencyImages[fromCurrency]} alt={fromCurrency} />
            <select value={fromCurrency} onChange={handleFromCurrencyChange}>
              {Object.keys(currencyImages).map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Fees */}
        <div className="my-4 bg-gray-200 rounded-xl p-4">
          <ul className="space-y-5 text-xl text-primary font-medium">
            <li className="flex justify-between">
              <span>0 USD</span>
              <span>Wire Transfer Fees</span>
            </li>
            <li className="flex justify-between">
              <span>0 USD</span>
              <span>Our Fees</span>
            </li>
          </ul>
        </div>

        {/* YOU RECIEVE */}
        <p className="text-primary font-semibold">You Recieve</p>
        <div className="shadow bg-white p-4 rounded-lg text-xl mt-2 flex justify-between w-full">
          <div>
            <input type="text" className="w-full outline-none" value={formatWithCommas(convertedAmount)} disabled />
          </div>
          <div className="flex items-center">
            <img className="w-[20px] h-[20px]" src={currencyImages[toCurrency]} alt={toCurrency} />
            <select value={toCurrency} onChange={handleToCurrencyChange}>
              {Object.keys(currencyImages).map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
        </div>

        

        <Link
          to={process.env.REACT_APP_REGISTER}
          className="flex justify-center text-white font-medium py-2 rounded-lg mt-3 bg-secondary text-center"
        >
          Waiting List
        </Link>
      </div>
    </div>
  );
};

export default CalculatorEn;
