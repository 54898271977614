import React from 'react'
import step1 from '../../../../assets/step1.webp'
import step2 from '../../../../assets/step2.webp'
import step3 from '../../../../assets/step3.webp'
import { Link } from 'react-router-dom'

const Steps = () => {
  const steps = [
    {
      name: "Paso 1",
      desc: "Envía los datos de tu empresa por la web o por WhatsApp.",
      icon: step1
    },
    {
      name: "Paso 2",
      desc: "Validaremos la información proporcionada.",
      icon: step2
    },
    {
      name: "Paso 3",
      desc: "Recibe tus accesos y cambia divisas con libertad.",
      icon: step3
    },
  ]
  return (
    <>
    <div className='max-w-[1300px] mx-auto px-6 mb-28'>
      <h1 className="text-4xl font-semibold text-primary text-center">Créate una cuenta gratis,<br />
      fácil y segura</h1>
      <div className="flex md:flex-row flex-col gap-9 my-6 justify-center">
        {steps.map((step, index) => {
          return (
            <div key={index} className='bg-white shadow-lg rounded-lg p-4 md:w-[25%]'>
              <div className="flex items-end justify-between mb-4">
                <h4 className="text-xl font-semibold text-secondary">{step.name}</h4>
                <img className='w-[70px]' src={step.icon} alt="" />
              </div>
              <p className='text-[#4a4a4a]'>{step.desc}</p>
            </div>
          )
        })}

      </div>
      <div className="flex justify-center">
      <Link className="text-center text-sm bg-secondary px-4 py-2 rounded text-white" target='_blank' to={process.env.REACT_APP_REGISTER}>
      Regístrate
      </Link>
    </div>
    </div>
    
    <div>

    </div>
    </>
  )
}

export default Steps
