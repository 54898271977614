import React from 'react'
import Calculator from '../../Calculator'
import { Link } from 'react-router-dom'

const Hero = () => {
    return (
        <div>
            <section className="h-full relative">

                <video className="absolute md:block hidden w-full h-full -z-20 object-fill" src=" https://tkambious-landing-cpfuhqe6c2gqgva5.eastus-01.azurewebsites.net/wp-content/themes/tkambious/assets/img/USA-LANDING-HOME/Desktop2_Inglés.webm" autoPlay loop muted __idm_id__="2392067"></video>

                <video className="absolute md:hidden block w-full h-full -z-20 object-fill" src=" https://tkambious-landing-cpfuhqe6c2gqgva5.eastus-01.azurewebsites.net/wp-content/themes/tkambious/assets/img/USA-LANDING-HOME/Mobile_Inglés.webm" autoPlay loop="" muted="" __idm_id__="2392066"></video>

                <div className="max-w-[1300px] mx-auto relative flex lg:flex-row flex-col md:justify-between md:items-center gap-0 lg:py-8 py-8 lg:pr-10 pr-2 lg:pl-10 pl-2">
                    <div className="flex flex-col lg:gap-20 items-center">
                        <div className="flex flex-col gap-1 md:justify-normal justify-center md:items-start items-center h-full">
                            <h1 className="md:text-4xl text-3xl md:text-left text-center md:font-extrabold font-bold text-primary w-full argentum-font">
                                <span className="md:block hidden">Unlock Global Potential, <br /> use 34 currencies in a single account</span>
                                <span className="md:hidden block">Unlock Global Potential, <br /> use 34 currencies in a <br /> single account</span>
                            </h1>
                            <p className="text-slate-600 md:text-2xl text-base md:font-semibold font-medium flex lg:flex-col flex-row">
                                <span className="md:block hidden">Multicurrency Account and Global Payments for <br /> Business</span>
                                <span className="md:hidden block text-center">Multicurrency Account and Global <br /> Payments for Business</span>
                            </p>
                        </div>
                        <div className="w-full flex md:justify-normal justify-center items-center gap-6 md:mt-0 mt-8">
                            <Link to={process.env.REACT_APP_REGISTER} target='_blank'>
                                <button className="bg-secondary md:px-6 px-4 md:py-2 py-1 md:text-base text-sm text-white rounded-lg hover:shadow-lg hover:translate-y-[-5px] duration-300">
                                    Waiting lists
                                </button>
                            </Link>
                            <Link to={process.env.REACT_APP_SCHEDULE_DEMO}>
                                <button className="bg-secondary md:px-6 px-4 md:py-2 py-1 md:text-base text-sm text-white rounded-lg hover:shadow-lg hover:translate-y-[-5px] duration-300">
                                    Schedule demo
                                </button>
                            </Link>
                        </div>
                    </div>
                    <Calculator />
                </div >
            </section >
        </div>
    )
}

export default Hero
