import React from 'react'
import MainHero from '../MainHero'
import heroimg from '../../assets/foreign-hero.png';
const Hero = () => {
  return (
    <div>
      <MainHero
      enheading="Secure the Best Exchange Rates"
      esheading="Asegure los mejores tipos de cambio"
      endesc="Maximize your profitability with our competitive rates."
      esdesc="Maximice su rentabilidad con nuestras tarifas competitivas."
      enbtn="Calculate your Savings"
      esbtn="Calcula tus ahorros"
      img={heroimg}
      />
    </div>
  )
}

export default Hero
