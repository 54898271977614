import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';

import quote from '../../assets/quote.svg'
import translations from "../../languageContent/TestimonialsContent";
import { LanguageContext } from "../../context/LanguageContext";


const Testimonials = () => {
  const {language} = useContext(LanguageContext);
  const t = translations[language];

  return (
    <div className="max-w-[1300px] mx-auto my-28">
        <h1 className="text-4xl mb-8 text-primary font-semibold text-center">
  {language === 'en' ? (
    <>
      Don't take our word <br /> for it, take theirs
    </>
  ) : (
    <>
      No confíes en nuestra palabra <br /> para ello, toma el suyo
    </>
  )}
</h1>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={30}
        slidesPerView={1}
        speed={5000}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        breakpoints={{
            600:{
                slidesPerView:2
            }
        }}
        
      >
        {(t || []).map((testimonial, index) => (
          <SwiperSlide key={index} className="bg-slate-400/20 rounded-lg p-4" >
            <div className="flex flex-col justify-between">
             <div className="flex justify-between items-center px-8">
             <img
                src={testimonial.image}
                alt={testimonial.name}
              />
              <img src={quote} alt="" />
             </div>
              <p className="my-3 text-sm text-black/60">"{testimonial.feedback}"</p>
              <h3 className="my-2 font-semibold text-black/80">{testimonial.name}</h3>
              <p className="text-black/60">{testimonial.country}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Testimonials;
