import React from 'react'
import step1 from '../../../../assets/step1.webp'
import step2 from '../../../../assets/step2.webp'
import step3 from '../../../../assets/step3.webp'

const Steps = () => {
  const steps = [
    {
      name: "Step 1",
      desc: "Send your company's data via the web or WhatsApp.",
      icon: step1
    },
    {
      name: "Step 2",
      desc: "We will validate the information provided.",
      icon: step2
    },
    {
      name: "Step 3",
      desc: "Receive your accessed within one or to two days and exchange currencies freely",
      icon: step3
    },
  ]
  return (
    <>
    <div className='max-w-[1300px] mx-auto px-6'>
      <h1 className="text-4xl font-semibold text-primary text-center">Take the first step</h1>
      <div className="flex md:flex-row flex-col gap-9 my-6 justify-center">
        {steps.map((step, index) => {
          return (
            <div key={index} className='bg-white shadow-lg rounded-lg p-4 md:w-[25%]'>
              <div className="flex items-end justify-between mb-4">
                <h4 className="text-xl font-semibold text-secondary">{step.name}</h4>
                <img className='w-[70px]' src={step.icon} alt="" />
              </div>
              <p className='text-[#4a4a4a]'>{step.desc}</p>
            </div>
          )
        })}

      </div>
    </div>
    
    <div>

    </div>
    </>
  )
}

export default Steps
