import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination,Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import mxn from '../../../../assets/MXN.png';
import cad from '../../../../assets/CAD.png';
import chf from '../../../../assets/CHF.png';
import aud from '../../../../assets/AUD.png';
import eur from '../../../../assets/EUR.png';
import gbp from '../../../../assets/GBP.png';
import jpy from '../../../../assets/JPY.png';
import usd from '../../../../assets/USD.png';
import inr from '../../../../assets/INR.png';
import aed from '../../../../assets/AED.svg';
import bgn from '../../../../assets/BGN.svg';
import brl from '../../../../assets/BRL.svg';
import cny from '../../../../assets/CNY.svg';
import czk from '../../../../assets/CZK.svg';
import dkk from '../../../../assets/DKK.svg';
import hdk from '../../../../assets/HDK.svg';
import hrk from '../../../../assets/HRK.svg';
import huf from '../../../../assets/HUF.svg';
import idr from '../../../../assets/IDR.svg';
import ils from '../../../../assets/ILS.svg';
import jod from '../../../../assets/JOD.svg';
import mur from '../../../../assets/MUR.svg';
import kwd from '../../../../assets/KWD.svg';
import krw from '../../../../assets/KRW.svg';
import kes from '../../../../assets/KES.svg';


const image = [mxn,cad,chf,aud,eur,gbp,jpy,usd,inr,aed,bgn,brl,cny,czk,dkk,hdk,hrk,huf,idr,ils,jod,mur,kwd,krw,kes]

const MultipleCurrency = () => {
  const data = [
    "Make international payments and collections efficiently and flexibly in 180 countries",
    "Forget about conversions and transfers through different bank accounts to have the currency",
    "Enjoy a competitive exchange rate on the market",
    "Save time and money with our comprehensive multicurrency account solution",
    "Manage your finances in a single platform: intuitive and easy to use",
  ];

  return (
    <div className="max-w-[1300px] mx-auto my-12 px-6">
      <h1 className="md:text-4xl text-3xl font-semibold text-primary text-center">
        Multiple currencies <br />
        in a single account
      </h1>
      
      {/* Desktop view */}
      <div className="md:flex hidden gap-4 my-8">
        {data.map((item, index) => (
          <div
            key={index}
            className="bg-[#EBEEF6] p-4 rounded-lg w-[260px] text-[#4a4a4a]"
          >
            {item}
          </div>
        ))}
      </div>

      {/* Mobile view */}
      <div className="md:hidden flex my-8">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={16}
          slidesPerView={1}
          loop={true}
          pagination={{ clickable: true }}
          className="w-full"
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="bg-[#EBEEF6] mb-9 p-4 rounded-lg w-full h-[120px] text-[#4a4a4a]">
                {item}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <Swiper
           modules={[Navigation, Pagination, Autoplay]} // Include Autoplay module
           spaceBetween={1}
          //  slidesPerView={20} // Adjust number of slides visible
           centeredSlides={true}
           slidesPerView={5}
           loop={true}
           delay={0}
           speed={2000}
           autoplay={{
             delay: 0,
             disableOnInteraction: false, // Keeps autoplay running even after user interaction
           }}
           breakpoints={{
            1024: {
              slidesPerView: 10, // 6 slides visible for screens >= 1024px
            },
            1280: {
              slidesPerView: 20, // 8 slides visible for screens >= 1280px
            },
          }}
           className="w-full"
        >
          {image.map((item, index) => (
            <SwiperSlide key={index}>
                <img className='w-[30px] h-[30px] rounded-full' src={item} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
    </div>
  );
};

export default MultipleCurrency;
