import React, { useContext } from "react";
import { IoArrowForward } from "react-icons/io5";
import { LanguageContext } from "../context/LanguageContext";
import { Link } from "react-router-dom";

const CalltoAction = () => {
  const { language } = useContext(LanguageContext);

  const translations = {
    en: {
      ready: "Ready to Get Started?",
      heading: "Start Managing Your Global Transactions.",
      desc: "Open your Tkambio multi-currency account today for seamless international banking",
      btn: "Sign up now!",
    },
    es: {
      ready: "¿Listo para comenzar?",
      heading: "Comience a administrar sus transacciones globales.",
      desc: "Abra hoy su cuenta multidivisa Tkambio para realizar operaciones bancarias internacionales sin problemas",
      btn: "¡Regístrate ahora!",
    },
  };
  const t = translations[language];
  return (
    <div className="my-28 max-w-[1200px] mx-auto">
      <div className="bg-[#F2F4FF] rounded-2xl md:p-16 py-8 px-3 flex flex-col items-center">
        <p className="text-[#50C878] md:text-xl">{t.ready}</p>
        <h3 className="md:text-4xl text-2xl font-semibold my-2 text-center">
          {t.heading}
        </h3>
        <p className="text-textcolor/60 font-medium text-xl md:w-[50%] text-center">
          {t.desc}
        </p>
        <Link target="_blank" to={process.env.REACT_APP_REGISTER} className="bg-secondary py-3 px-9 rounded-full text-white flex items-center my-6">
          {t.btn}
          <IoArrowForward />
        </Link>
      </div>
    </div>
  );
};

export default CalltoAction;
