import React, { useEffect } from 'react'
import Hero from '../components/TkambioCredit/Hero'
import UnlockPower from '../components/UnlockPower'
import LogisticCard from '../components/LogisticCard'
// import Questions from '../components/Questions'
import CalltoAction from '../components/CalltoAction'
import CardsSection from '../components/TkambioCredit/CardsSection'
import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const TkambioCredit = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:true,
      mirror: true, 
    });
  }, []);

  const sections = [<Hero/>,<UnlockPower/>,<CardsSection/>,<LogisticCard/>,<CalltoAction/>]

  return (
    <div className='pt-[6rem]'>
      {sections.map((sec,index)=>{
        return(
          <div data-aos="fade-up" data-aos-delay={`${index * 100}`}>{sec}</div>
        )
      })}
    </div>
  )
}

export default TkambioCredit
