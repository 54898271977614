import React from 'react'
import MainHero from '../MainHero'
import heroimg from '../../assets/hero-img.png';
const Hero = () => {
  return (
    <div>
      <MainHero
      enheading="Unlock the Doors to Global Potential for Your Business"
      esheading="Desbloquea las Puertas al Potencial Global para tu Negocio"
      endesc="Manage multiple currencies with ease, streamline transactions, and unlock global growth—all from one powerful account."
      esdesc="Administra múltiples divisas con facilidad, agiliza las transacciones y desbloquea el crecimiento global, todo desde una sola cuenta poderosa."
      enbtn="Get Started"
      esbtn="Empezar"
      img={heroimg}
      />
    </div>
  )
}

export default Hero
