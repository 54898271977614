import React, { useEffect } from 'react'
import Hero from './parts/Hero'
import MultipleCurrency from './parts/MultipleCurrency'
import Dashboard from './parts/Dashboard'
import Steps from './parts/Steps'
import CalltoAction from './parts/CalltoAction'
import WhyChoose from './parts/WhyChoose'
import Testimonials from '../Testimonials'

import AOS from 'aos';  // Import AOS
import 'aos/dist/aos.css';

const HomeEn = () => {

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 500, 
      once:true,
      mirror: true, 
    });
  }, []);

  const sections = [<Hero/>,<MultipleCurrency />,<Dashboard />,<Steps/>,<CalltoAction />,<WhyChoose />,<Testimonials />]


  return (
    <div>
      {sections.map((sec,index)=>{
        return(
          <div key={index} data-aos="fade-up" data-aos-delay={`${index * 100}`}>{sec}</div>
        )
      })}
      
    </div >
  )
}

export default HomeEn
