import React, { useContext } from 'react'
import Card from '../Card'
import { LanguageContext } from '../../context/LanguageContext'
import boostimg from '../../assets/boost-profile.png'
import leverageimg from '../../assets/leverage.png'
import supportimg from '../../assets/support.png'

const CardSection = () => {
    const {language} = useContext(LanguageContext);

    const translations = {
        en:{
            boostProfile:"Boost Your Profits with Competitive Exchange Rates",
            boostdesc:"Leverage our attractive rates to enhance your trading profitability while minimizing costs. Secure the best rates to optimize your financial transactions and gain a competitive edge.",
            boostBtn:"Get Started",

            leverage:"Leverage Our Tools to Manage Currency Risk",
            leveragedesc:"Utilize our risk management solutions to hedge against currency fluctuations, protecting your investments and ensuring financial stability for your business.",
            leverageBtn:"Get Started",

            support:"Customized Support for Your Trading Success",
            supportdesc:"Receive customized guidance from our expert team to streamline your trading experience and enhance decision-making.",
            supportBtn:"Get Started"
        },
        es:{
            boostProfile:"Aumente sus ganancias con tipos de cambio competitivos",
            boostdesc:"Aproveche nuestras atractivas tarifas para mejorar la rentabilidad de sus operaciones y al mismo tiempo minimizar los costos. Obtenga las mejores tarifas para optimizar sus transacciones financieras y obtener una ventaja competitiva.",
            boostBtn:"Empezar",

            leverage:"Aproveche nuestras herramientas para gestionar el riesgo cambiario",
            leveragedesc:"Utilice nuestras soluciones de gestión de riesgos para protegerse contra las fluctuaciones monetarias, proteger sus inversiones y garantizar la estabilidad financiera de su negocio.",
            leverageBtn:"Empezar",

            support:"Soporte personalizado para su éxito comercial",
            supportdesc:"Reciba orientación personalizada de nuestro equipo de expertos para optimizar su experiencia comercial y mejorar la toma de decisiones.",
            supportBtn:"Empezar"
        }
    }

    const t = translations[language]
  return (
    <div className='my-52'>
      <Card
        title={t.boostProfile}
        p={t.boostdesc}
        btn={t.boostBtn}
        img={boostimg}
      />
      <Card
        title={t.leverage}
        p={t.leveragedesc}
        btn={t.leverageBtn}
        img={leverageimg}
        styles={'order-first'}
      />
      <Card
        title={t.support}
        p={t.supportdesc}
        btn={t.supportBtn}
        img={supportimg}
      />
    </div>
  )
}

export default CardSection
