import React, { useContext } from 'react'
import { LanguageContext } from '../../context/LanguageContext'
import CalculatorEn from './CalculatorEn';
import CalculatorEs from './CalculatorEs';

const Calculator = () => {
    const {language} = useContext(LanguageContext);
  return (
    <div>
      {language === 'en' ? <CalculatorEn/> : <CalculatorEs/>}
    </div>
  )
}

export default Calculator
