import React from 'react'
import MainHero from '../MainHero'
import heroimg from '../../assets/foreign-hero.png';
const Hero = () => {
  return (
    <div>
      <MainHero
      enheading="Empower Your Business with Tkambio Credit"
      esheading="Potencia tu negocio con crédito Tkambio"
      endesc="Unlock flexible financing solutions tailored to fuel your growth and support your business needs, whenever you need it."
      esdesc="Descubra soluciones de financiación flexibles diseñadas para impulsar su crecimiento y satisfacer las necesidades de su negocio, cuando lo necesite."
      enbtn="Join Waitlist"
      esbtn="Unirse a la lista de espera"
      img={heroimg}
      />
    </div>
  )
}

export default Hero
