import React, { useEffect, useState, useRef, useMemo } from 'react';
import Slider from 'react-slick';
import euro from '../assets/EUR.png';
import cad from '../assets/CAD.png';
import chf from '../assets/CHF.png';
import gbp from '../assets/GBP.png';
import jpy from '../assets/JPY.png';
import mxn from '../assets/MXN.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
    const [rates, setRates] = useState([]);
    const ratesRef = useRef([]);

    // Memoized currency data
    const currencies = useMemo(() => [
        { img: euro, name: 'EUR', code: 'EUR' },
        { img: cad, name: 'CAD', code: 'CAD' },
        { img: chf, name: 'CHF', code: 'CHF' },
        { img: gbp, name: 'GBP', code: 'GBP' },
        { img: jpy, name: 'JPY', code: 'JPY' },
        { img: mxn, name: 'MXN', code: 'MXN' }
    ], []);

    // Fetch exchange rates
    const fetchRates = async () => {
        try {
            const fetchedRates = await Promise.all(
                currencies.map(async (currency) => {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}?pair=USD${currency.code}=X`
                    );
                    const data = await response.json();
                    return {
                        ...currency,
                        rate: data.regularMarketPrice,
                    };
                })
            );
            ratesRef.current = fetchedRates;
            setRates(fetchedRates);
        } catch (error) {
            console.error('Error fetching exchange rates:', error);
        }
    };

    useEffect(() => {
        fetchRates();
        const intervalId = setInterval(fetchRates, 600000); // Update every 10 mins
        return () => clearInterval(intervalId); // Cleanup
    }, [currencies]);

    // Slider settings
    const sliderSettings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 1000,
        arrows: false,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ],
    };

    return (
        <div className="max-w-[1300px] mx-auto px-6 py-2">
            <div className="flex items-center">
                <div className="bg-secondary py-2 px-6 text-white rounded-full font-semibold mr-2">
                    Fix Live
                </div>
                <div className="md:w-[80%] w-[60%]">
                    <Slider {...sliderSettings}>
                        {rates.length > 0 ? (
                            rates.map((currency, index) => (
                                <div key={index} className="flex justify-center">
                                    <div className="flex items-center border-2 py-1 px-4 md:px-6 rounded-full">
                                        <img
                                            className="w-[30px] h-[30px] md:w-[30px] md:h-[30px] rounded-full mx-2"
                                            src={currency.img}
                                            alt={currency.name}
                                            loading="lazy" // Lazy load images
                                        />
                                        <div className="flex items-center justify-between gap-5">
                                            <p>{currency.name}</p>
                                            <p className="ml-2">${currency.rate.toFixed(2)}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            // Loading placeholders
                            Array.from({ length: 3 }).map((_, index) => (
                                <div key={index} className="flex justify-center">
                                    <div className="flex items-center border-2 py-1 px-4 md:px-6 rounded-full animate-pulse">
                                        <div className="w-[30px] h-[30px] md:w-[30px] md:h-[30px] rounded-full mx-2 bg-gray-300"></div>
                                        <div className="flex items-center justify-between gap-5">
                                            <p className="w-16 h-4 bg-gray-300 rounded"></p>
                                            <p className="ml-2 w-10 h-4 bg-gray-300 rounded"></p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Banner;
