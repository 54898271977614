import React, { useContext } from "react";
import support from '../../assets/24-hour-support.png';
import { LanguageContext } from "../../context/LanguageContext";

import fasttransaction from '../../assets/fast-transactions.png'
import expertguide from '../../assets/expert-guide.png';
const Banner = () => {
    const {language} = useContext(LanguageContext)
    const translations = {
        en: {
          fastTrans: "Fast Transactions",
          fastTransDesc:"Enjoy quick domestic and international exchanges for timely access to funds.",

          expertGuide:"Expert Guidance",
          expertDesc:"Get personalized advice from our team to navigate foreign exchange complexities.",

          hourSupport:"24/7 Support",
          hourSupportDesc:"Count on expert assistance anytime to ensure smooth, secure global transactions."
        },
        es: {
          fastTrans: "Transacciones rápidas",
          fastTransDesc:"Disfrute de rápidos intercambios nacionales e internacionales para acceder oportunamente a los fondos.",

            expertGuide:"Orientación experta",
            expertDesc:"Obtenga asesoramiento personalizado de nuestro equipo para afrontar las complejidades del tipo de cambio.",

            hourSupport:"24/7 apoyo",
            hourSupportDesc:"Cuente con asistencia experta en cualquier momento para garantizar transacciones globales seguras y sin problemas."
        },
      };


    const t = translations[language];
    const banner = [
        { img: fasttransaction, title: t.fastTrans, desc: t.fastTransDesc },
        {img:expertguide,title:t.expertGuide,desc:t.expertDesc},
        {img:support,title:t.hourSupport,desc:t.hourSupportDesc},
      ]
    
  return (
    <div>
      <div className="bg-secondary py-12 px-4">
        <div className="max-w-[1200px] mx-auto flex md:flex-row flex-col gap-6 justify-between text-white">
          {banner.map((item, idx) => (
            <div key={idx} className="flex-1">
              <img src={item.img} alt={item.title} className="w-16 mb-4" />
              <h4 className="text-xl md:text-2xl font-semibold">
                {item.title}
              </h4>
              <p className="text-white/70">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Banner;
