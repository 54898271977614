import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import goal from '../../assets/managing-goal.png';
import { Link } from "react-router-dom";

const FAQs = () => {
  const faqs = [
    "Who can register",
    "Do you charge for account opening",
    "What bank do you partner with?",
    "Can i open multiple currencies account",
    "What are document required to get started with you",
  ];

  return (
    <div className="mt-28 max-w-[1200px] mx-auto">
      <h5 className="text-xl text-[#50C878] text-center font-semibold">Faqs</h5>
      <div className="flex justify-center my-2">
        <h3 className="md:w-[60%] md:text-3xl text-xl md:mx-0 mx-2 font-semibold text-center text-primary">
          Get answers to common questions about our multi-currency accounts.
        </h3>
      </div>

      <div className="mt-16 mb-28">
        {faqs.map((q, index) => {
          return (
            <div
              key={index}
              className=" group cursor-pointer bg-secondary p-4 flex justify-between mt-3 text-white text-xl font-medium rounded-lg"
            >
              <div className="group-hover:underline">{q}</div>
              <IoIosArrowForward />
            </div>
          );
        })}
      </div>

      {/* END CARD */}
      <div className="bg-[#F2F4FF] rounded-lg my-9 md:flex justify-between">
        <div className="md:pt-24 md:pl-16 p-8">
            <h1 className="text-4xl font-semibold">Start Managing Your Global Transactions.</h1>
            <p className="text-textcolor/60 font-semibold text-xl mt-4 mb-8">Open your Tkambio multi-currency account today for seamless international banking</p>
            <Link to={process.env.REACT_APP_REGISTER} target="_blank" className="bg-secondary py-3 px-9 rounded-full text-white">Sign up now!</Link>
        </div>
        <img className="pt-8" src={goal} alt="" />
      </div>
    </div>
  );
};

export default FAQs;
