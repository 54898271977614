import React, { useContext } from 'react'
import { LanguageContext } from '../context/LanguageContext'
import HomeEn from '../components/Home/HomeEnglish/HomeEn'
import HomeEs from '../components/Home/HomeSpanish/HomeEs'
import Banner from '../components/Banner'

const Home = () => {
  const {language} = useContext(LanguageContext)
  return (
    <div className='pt-[5.7rem]'>
      <Banner/>
      {language === "en" ? <HomeEn /> : <HomeEs />}
    </div>
  )
}

export default Home
